angular.module('portfolio_web.summary.goals.directives', [])

.directive('summaryGoals', [
	'$q',
	'$timeout',
	'$filter',
	'$state',
	'locale',
	'NwuiSession',
	'ApiGoalsService',
	'RiskProfileService',
	'NwButtonService',
	'NwuiTargetFactory',
	'NwuiRecommendationFactory',
	'NwuiGoalProgressFactory',
	'NwuiOverlayFactory',
	'NwuiButtonV3Factory',
	'NwuiChartLineFactory',
	'NwuiChartDonutV3Factory',
	'NwuiCarouselNavigationFactory',
	'ApiUserService',
	function(
		$q,
		$timeout,
		$filter,
		$state,
		locale,
		NwuiSession,
		ApiGoalsService,
		RiskProfileService,
		NwButtonService,
		NwuiTargetFactory,
		NwuiRecommendationFactory,
		NwuiGoalProgressFactory,
		NwuiOverlayFactory,
		NwuiButtonV3Factory,
		NwuiChartLineFactory,
		NwuiChartDonutV3Factory,
		NwuiCarouselNavigationFactory,
		ApiUserService
	) {
		return {
			restrict: 'E',
			replace: true,
			templateUrl: 'portfolio_webserver/summary/goals/template.html',
			scope: {},
			link: function(scope, element, attrs) {
				function isPartOfHistoricalResolveCaAccount(goal) {
					
					if (NwuiSession.user.org.slug !== 'resolveca') {
						return false;
					}

					// Any ResolveCA account that was created before this date should temporarily have certain UI elements hidden
					// until we can ingest their historical data from FCC. See PMO-381.
					var RESOLVECA_PRE_EXISTING_ACCOUNTS_CUTOFF_DATE = moment('20191201');

					scope.historicalPerformanceRecommendation = new NwuiRecommendationFactory({
						type: 'info',
						icon: {
							name: 'info'
						},
						text_i18n: 'nwui.common.line_graph.awaiting_historical_data_message'
					});

					var account = goal.Accounts ? goal.Accounts[0] : null;

					var accountOpenDate;
					if(account && account.open_date && moment(account.open_date).toString() !== 'Invalid date') {
						accountOpenDate = moment(account.open_date);
					} else {
						accountOpenDate = null;
					}
		
					if(accountOpenDate && accountOpenDate < RESOLVECA_PRE_EXISTING_ACCOUNTS_CUTOFF_DATE) {
						return true;
					}

					return false;
				}

				var goalCardMap = function(goal,i) {
					return {
						style: {},
						overlay: new NwuiOverlayFactory({
							id: 'goal-summary-overlay-' + goal.id,
							show: true,
							block_state_change: true,
							message_i18n: "summary.goal.card.loading_details",
							message_i18n_data: {
								goalName : goal.localize ? locale.getString(goal.description) : goal.description
							},
							icon: { 
								name: 'donut_large'
							}
						}),
						header: {
							name: goal.localize ? locale.getString(goal.description) : goal.description,
							icon: {
								name: $filter('NwuiIconAutoPicker')(goal.description, 'flag')
							},
							level: 2
						},
						progression: {
							target: undefined,
							goal_progress: undefined,
							edit_button: new NwuiButtonV3Factory({
								id: 'goal-edit-button-' + goal.id,
								text_i18n: 'summary.goal.tracking.button_edit',
								icon: {
									name: 'create'
								},
								size: 'short',
								color: 'alternate',
								onClick: function() {
									
									scope.compounds.goal_target_modal.goal = goal;
									scope.compounds.goal_target_modal.open = true;
							
								}
							}),
							copy: {
								buttons: {
									edit: new NwuiButtonV3Factory({
										id: 'goal-edit-target-' + goal.id,
										text_i18n: 'summary.goal.tracking.button_set',
										icon: {
											name: 'create'
										},
										hollow: scope.data.has_funding_enhancements,
										color: scope.data.has_funding_enhancements ? organization_data.theme.form.submit.primary : organization_data.theme.form.submit.error_color,
										onClick: function() {
											
											scope.compounds.goal_target_modal.goal = goal;
											scope.compounds.goal_target_modal.open = true;
									
										}
									})
								}
							},
							recommendation: undefined
						},
						balance: {
							part_of_historical_resolveca_account: isPartOfHistoricalResolveCaAccount(goal),
							contribute_button: new NwuiButtonV3Factory({
								id: 'goal-contribute-button-' + goal.id,
								text_i18n: 'summary.goal.balance.button_contribute',
								icon: {
									name: 'add_box'
								},
								size: 'short',
								color: 'alternate',
								onClick: function() {
									
									//NwButtonService.buttons['goal-contribute-button-' + goal.id].processing = true;
									$state.go('app.transfers.overview');
							
								}
							}),
							funding_button: new NwuiButtonV3Factory({
								id: 'goal-transfer-funds-' + goal.id,
								text_i18n: 'summary.goal.balance.button_contribute_start',
								icon: {
									name: 'add'
								},
								color: scope.data.has_funding_enhancements ? organization_data.theme.form.submit.primary : organization_data.theme.form.submit.error_color,
								onClick: function() {
									NwButtonService.buttons['goal-transfer-funds-' + goal.id].processing = true;
									$state.go('app.transfers.overview', {account_id: goal.Accounts[0].id});
								}
							}),
							recommendation: new NwuiRecommendationFactory({
								type: 'error',
								icon: {
									name: 'attach_money'
								},
								text_i18n: "summary.goal.balance.message_unfunded",
								button: undefined
							}),
							chart: new NwuiChartLineFactory({
								series: [],
								units: 'currency',
								trim_dates: true,
								//height_ratio: 50,
								horiz_axis_labels: false
							}),
							copy: {
								buttons: {
									funding: new NwuiButtonV3Factory({
										id: 'goal-funding-' + goal.id,
										text: 'summary.goal.balance.button_fund_goal',
										icon: {
											name: 'attach_money'
										},
										color: 'secondary',
										onClick: function() {
											
											//OPEN MODAL
									
										}
									})
								}	
							}
						},
						allocation: {
							risk_profile_button: new NwuiButtonV3Factory({
								id: 'goal-risk-profile-button-' + goal.id,
								text_i18n: 'summary.goal.allocation.button',
								icon: {
									name: 'pie_chart'
								},
								size: 'short',
								color: 'alternate',
								onClick: function() {
									
									//NwButtonService.buttons['goal-risk-profile-button-' + goal.id].processing = true;
									$state.go('app.manage.risk_profiles.view', { goal_id: goal.id });
							
								}
							}),
							chart: new NwuiChartDonutV3Factory({
								active: undefined,
								series: [],
								decimals: 0
							})
						},
						carousel_navigation: new NwuiCarouselNavigationFactory({
							carousel_id: i,
							count: 3,
							current: 0,
							onChange: function(data) {
								
								////console.log('SummaryController', 'carousel onchange', data);
								scope.cards.goals[i].style.left = -100 * data.current + '%';
								
							}
						})
					}
				}

				function setLanguageKey(val){
					if(!val) return undefined;

					return val.replace(/[^\w\s]/g , "").replace(/ +/g , "_").toLowerCase();
				}

				var loadGoal = function(goal, i, callback) {
					
					var deferred = $q.defer();
					scope.cards.goals[i].overlay.show = true;
					
					ApiGoalsService.getHoldings(goal.id).then(function(obj) {
						goal.holdings = obj.data.market_value;
						
						ApiGoalsService.getTracking(goal).then(function(obj) {
							goal.tracking = obj.data;
							goal.tracking.on_track = (goal.tracking.delta >= 0);
							scope.cards.goals[i].progression.target = loadTarget(goal);
							scope.cards.goals[i].progression.goal_progress = loadGoalProgress(goal);
							scope.cards.goals[i].progression.recommendation = loadRecommendation(goal);
						});
						
						if(goal.holdings > 0) {
							ApiGoalsService.getAllocation(goal).then(function(obj) {
								//console.log('summaryGoals', 'ApiGoalsService.getAllocation', obj.data);
								goal.allocation = obj.data;
														
								scope.cards.goals[i].allocation.chart.series = Object.keys(goal.allocation.asset_classes).map(function(key,i) {
									var _type = goal.allocation.asset_classes[key].type;
									return {
										name: goal.allocation.asset_classes[key].description,
										i18n_key: setLanguageKey(goal.allocation.asset_classes[key].description),
										percent: goal.allocation.asset_classes[key].percent * 100,
										type: (goal.allocation.asset_classes[key].description.toLowerCase().includes('alternative')) ? 'alternatives' : _type,
									}
								});
								
								ApiGoalsService.getBalanceSeries(goal).then(function(obj) {
									//console.log('summaryGoals', 'ApiGoalsService.getBalanceSeries', obj);
									goal.balance_graph = obj.data;
									scope.cards.goals[i].balance.chart.series = goal.balance_graph.series.map(function(item,i) {
										return {
											value: item.close.dollar,
											date: item.date
										}
									});
									
									scope.cards.goals[i].overlay.show = false;
									callback();
								});
							});
						} else {
							RiskProfileService.getRiskProfile(goal.id).then(function(obj) {
								//console.log('summaryGoals', 'RiskProfileService.getRiskProfile', obj);
								goal.risk_profile = obj.risk_profiles.current;
								
								scope.cards.goals[i].allocation.chart.series = goal.risk_profile.pie_data.data.map(function(datum,i) {
									return {
										name: datum.description,
										i18n_key: setLanguageKey(datum.description),
										percent: datum.percent,
										type: (datum.description.toLowerCase().includes('alternative')) ? 'alternatives' : datum.type,
									}
								});
								
								scope.cards.goals[i].overlay.show = false;
								callback();
							});
						}
					});
					
					ApiGoalsService.getPerformance(goal).then(function(obj) {
						goal.performance = obj.data;
					});
					
				}

				var loadTarget = function(goal) {
					
					return new NwuiTargetFactory({
						type: goal.tracking.on_track ? '' : 'warning',
						text_i18n: goal.tracking.not_set ? 'summary.goal.tracking.status_not_set' : goal.tracking.on_track ? 'summary.goal.tracking.status_on_track' : 'summary.goal.tracking.status_off_track',
						status: goal.tracking.not_set ? 'not-set' : goal.tracking.on_track ? 'on-track' : 'off-track'
					})
						
				}
				
				var loadRecommendation = function(goal) {
					
					return new NwuiRecommendationFactory({
						type: (goal.tracking.not_set) ? 'error' : (goal.tracking.on_track) ? '' : 'warning',
						icon: {
							name: (goal.tracking.not_set) ? 'warning' : (goal.tracking.on_track) ? 'check' : 'lightbulb_outline'	
						},
						text_i18n: (goal.tracking.not_set) 
						? 'summary.goal.tracking.message_not_set' 
						: (goal.tracking.on_track) ? 'summary.goal.tracking.message_on_track' 
						: 'summary.goal.tracking.message_off_track',
						button: undefined,
						text_i18n_data: {
							delta :  $filter("currency")(goal.tracking.delta, undefined, 0),
							monthlyContribution: $filter("currency")(goal.yearly_contribution/12, undefined, 0),
							monthlyFix: $filter("currency")(goal.tracking.monthly_fix, undefined, 0)
						}
					});
					
				}

				var loadGoalProgress = function(goal) {
					
					return new NwuiGoalProgressFactory({
						current: goal.holdings,
						delta: goal.tracking.delta,
						target: goal.target_amount,
						date: goal.target_date
					})
					
				}

				scope.data = {
					goals: undefined,
					hide_transfers: organization_data.port_data.hide_transfers,
					show_goal_performance: lodashGet(organization_data, 'port_data.goals.show_performance', true),
					has_funding_enhancements: lodashGet(organization_data, 'port_data.summary.ui.funding_styles', false),
				};

				scope.cards = {
					goals: [],
					accountLevelQuestions: []
				}

				scope.cutouts = {
					add_new_goal: {
						button: new NwuiButtonV3Factory({
							id: 'add-goal-button',
							text_i18n: 'summary.goal.tracking.button_new_goal',
							color: 'secondary',
							icon: {
								name: 'create'
							},
							onClick: function() {
								
								scope.compounds.add_goal_modal.open = true;
						
							}
						})
					}
				}

				scope.compounds = {
					split_goal_modal: {
						open: false,
						goal: undefined,
						source_goals: undefined,
						formResource: ApiGoalsService.splitGoal,
						submitCb: function(obj) {
							
							//console.log('summaryGoals', 'split_goal submitCb', obj);
							
							scope.cards.goals.forEach(function(goal,i) {
								for(var key in obj.goal_ids) {
									if(obj.goal_ids[key] == scope.data.goals[i].id) {
										loadGoal(scope.data.goals[i], i, function() {});
									}
								}
							});
							
						}
					},
					add_goal_modal: {
						open: false,
						formResource: ApiGoalsService.createGoal,
						submitCb: function(new_goal) {
							
							////console.log('summaryGoals', 'add_goal_modal submitCb', new_goal);
							
							scope.data.goals.push(new_goal);
							scope.cards.goals.push(goalCardMap(new_goal, scope.data.goals.length-1));
							loadGoal(new_goal, scope.data.goals.length-1, function() {});
							
						}
					},
					goal_target_modal: {
						open: false,
						goal: undefined,
						formResource: ApiGoalsService.setTarget,
						submitCb: function(new_goal) {
							
							////console.log('summaryGoals', 'goal_target_modal submitCb', new_goal);
							
							new_goal.tracking.on_track = (new_goal.tracking.delta >= 0);
							scope.cards.goals.forEach(function(goal,i) {
								if(new_goal.id == scope.data.goals[i].id) {
									scope.data.goals[i].name = new_goal.localize ? locale.getString(new_goal.description) : new_goal.description;
									angular.extend(scope.data.goals[i], new_goal);
									scope.cards.goals[i].header.name = new_goal.localize ? locale.getString(new_goal.description) : new_goal.description;
									scope.cards.goals[i].header.icon.name = $filter('NwuiIconAutoPicker')(new_goal.description, 'flag');
									scope.cards.goals[i].progression.recommendation = loadRecommendation(scope.data.goals[i]);
									scope.cards.goals[i].progression.goal_progress = loadGoalProgress(scope.data.goals[i]);
									scope.cards.goals[i].progression.target = loadTarget(scope.data.goals[i]);
								}
							});
								
						}
					}
				}

				var fetchData = function() {
					var deferred = $q.defer()

					ApiGoalsService.getGoals().then(function(obj) {
						deferred.resolve(obj.data);
					});
			
					return deferred.promise;
				}

				fetchData().then(function(res) {

					scope.data.goals = res;

					scope.cards.goals = scope.data.goals.map(goalCardMap);
					//console.log(scope.data.goals);
					var promises = [];
					scope.data.goals.forEach(function(goal,i) {
						promises.push(function(callback) { loadGoal(goal,i,callback) });
					});
					
					async.series(promises, function(err) {});
					
				});

			}
		}
	}
]);
