angular.module('portfolio_web.accounts.view.controllers', [
	'ui.router',
	'portfolio_web.constants',
	'portfolio_web.legacy.directives'
])

.controller('AccountsViewController', [
	'$scope', 
	'$q', 
	'$state', 
	'$timeout', 
	'$filter', 
	'$stateParams',
	'$sce',
	
	'ApiClientsService',
	'ApiAccountsService',
	'ApiGoalsService',
	'GoalService', 
	'RiskProfileService', 
	'NwuiInputService',
	'NwuiOverlayService',
	'NwButtonService',
	'EftService',

	'NwuiButtonV3Factory',
	'NwuiButtonV2Factory', 
	'NwuiInputSelectV2Factory',
	'NwuiCardHeaderV2Factory',
	'NwuiCardBigNumberFactory',
	
	'NwuiChartLineFactory',
	'NwuiFormFactory',
	'NwuiFormV3Factory',
	'NwuiRecommendationFactory',
	'NwuiCardTableV3Factory',

	'NwuiSession',
	'DATE_FORMAT',
	'NWUI_SELECT_OPTIONS',
	'ResolveDataAsOf',
	'ResolveAccounts',
	'ResolveDestinationAccounts',
	'PowerFormBaseUrl',
	
	'locale',
	function(
		$scope, 
		$q, 
		$state, 
		$timeout, 
		$filter, 
		$stateParams,
		$sce,
		
		ApiClientsService,
		ApiAccountsService,
		ApiGoalsService,
		GoalService, 
		RiskProfileService, 
		NwuiInputService,
		NwuiOverlayService,
		NwButtonService,
		EftService,

		NwuiButtonV3Factory,
		NwuiButtonV2Factory,
		NwuiInputSelectV2Factory,
		NwuiCardHeaderV2Factory,
		NwuiCardBigNumberFactory,
		NwuiChartLineFactory,
		NwuiFormFactory,
		NwuiFormV3Factory,
		NwuiRecommendationFactory,
		NwuiCardTableV3Factory,

		NwuiSession,
		DATE_FORMAT,
		NWUI_SELECT_OPTIONS,
		ResolveDataAsOf,
		ResolveAccounts,
		ResolveDestinationAccounts,
		PowerFormBaseUrl,
		
		locale
	) {
		// Any ResolveCA account that was created before this date should temporarily have certain UI elements hidden
		// until we can ingest their historical data from FCC. See PMO-381.
		var RESOLVECA_PRE_EXISTING_ACCOUNTS_CUTOFF_DATE = moment('20191201');

		function getAccountType(account) {

			var account_type;

			if (account.slug) {
				var slug = 'nwui.account_types.' + account.slug + '.name';
				account_type = locale.isTranslated(slug)
					? locale.getString(slug)
					: account.account_type;
			} else {
				account_type = locale.getString('nwui.account_types.' + account.account_type + '.name') || account.account_type;
			}

			return account_type;
		}

		function accountOptionMap(account,i) {
			var template = account.custodian_id
				? 'nwui.common.input.destination_account.option'
				: 'nwui.common.input.destination_account.option_aggregate';

			return {
				id: i,
				// text: account.account_type + custodian_id,
				text_i18n: template,
				text_i18n_data: {
					accountType: getAccountType(account),
					accountId: account.custodian_id
				},
				value: account.id
			}
		}

		function setLanguageKey(val){
			if(!val) return undefined;

			return val.replace(/[^\w\s]/g , "").replace(/ +/g , "_").toLowerCase();
		}

		////console.log('AccountsViewController', 'Loaded', ResolveAccounts);
		
		var loadRecommendation = function() {

			$q.all([
				EftService.getEftsAccounts(), 
				EftService.getPendingEftSources(NwuiSession.user.client_id)
			]).then(function(results) {

				$scope.data.eft_accounts = $scope.compounds.create_eft.accounts_efts = results[0].data;
				$scope.data.eft_sources = results[1].data;
				$scope.cards.withdrawal.button.text_i18n = "manage.withdrawals.title";
		        $scope.cards.withdrawal.button.onClick = function () {
					var powerFormUrl; 
					if(org_slug === 'md'){
						const powerFormUrlFromConfig = (locale.getLocale() === 'en-CA') ? organization_data.powerFormBaseUrlEn : organization_data.powerFormBaseUrlFr;
						powerFormUrl = `${powerFormUrlFromConfig}`;
					}else{
						powerFormUrl = `${PowerFormBaseUrl}=${$scope.data.active_account.id}&v=2`;
					}
					window.open(powerFormUrl, null, "noreferrer");
		        };
	
				var flag = false;

				//check if account has EFT source set up
				$scope.data.eft_accounts.forEach(function(account,i) {
					if(account.id === $scope.data.active_account.id) {
						flag = true;
					}
				});

				
				if(flag || $scope.data.active_account.id === 'aggregate') {
					//has active source

					$scope.cards.info.recommendation = undefined;

					//button parameters
					$scope.cards.info.button.icon = {
						name: 'attach_money'
					}
					// $scope.cards.info.button.text = 'Transfer Funds';
					$scope.cards.info.button.text_i18n = 'accounts.view.transfer.button_ready';
					$scope.cards.info.button.color = '';
					$scope.cards.info.button.hollow = false;
					$scope.cards.info.button.onClick = function() {
						
						if($scope.data.use_transfers_page) {
							$state.go($scope.data.risk_profile_approval_required ? 'app.transfers.new.type.destination' : 'app.transfers.new.type.destination.form', { transfer_type: 'chequing-savings', account_id: $stateParams.account_id });
						} else
							$scope.compounds.create_eft.open = true;

						// NwButtonService.buttons['info-add-eft-source-button'].processing = true;

						// NwuiOverlayService.overlays['global-overlay'].icon.name = "swap_horiz";
						// NwuiOverlayService.overlays['global-overlay'].message = "Loading Transfers...";
						// NwuiOverlayService.overlays['global-overlay'].show = true;

						// $timeout(function() {
						// 	$state.go('app.transfers');
						// 	NwButtonService.buttons['info-add-eft-source-button'].processing = false;
						// }, 500);

					}

				} else if($scope.data.eft_sources.length > 0) {
					//has pending sources

					$scope.cards.info.recommendation = new NwuiRecommendationFactory({
						type: 'warning',
						icon: {
							name: 'access_time'
						},
						// text: "We're setting up your transfer source. This process can take up to 3 business days.",
						text_i18n: 'accounts.view.transfer.message_pending',
						button: undefined
					})

					//button parameters
					$scope.cards.info.button.icon = {
						name: 'link'
					}
					// $scope.cards.info.button.text = 'View Sources';
					$scope.cards.info.button.text_i18n = 'accounts.view.transfer.button_pending';
					$scope.cards.info.button.color = 'warning';
					$scope.cards.info.button.hollow = false;
					$scope.cards.info.button.onClick = function() {
							
						NwButtonService.buttons['info-add-eft-source-button'].processing = true;

						$timeout(function() {
							$state.go('app.manage.transfer_sources.list');
						}, 500);
					
					}

				} else {

					$scope.cards.info.recommendation = new NwuiRecommendationFactory({
						type: 'error',
						icon: {
							name: 'warning'
						},
						// text: "You haven't set up a chequing/savings account transfer source for this account&nbsp;yet.",
						text_i18n: 'accounts.view.transfer.message_none',
						button: undefined
					})

					//button parameters
					$scope.cards.info.button.icon = {
						name: 'create'
					}
					// $scope.cards.info.button.text = 'Add Source';
					$scope.cards.info.button.text_i18n = 'accounts.view.transfer.button_none';
					$scope.cards.info.button.color = 'error';
					$scope.cards.info.button.hollow = false;
					$scope.cards.info.button.onClick = function() {

						if($scope.data.supports_eft_source_page) {
							$state.go('app.manage.transfer_sources.new');
						} else {
							$scope.compounds.add_eft_source.open = true;
						}

					}

				}

			});

		}

		$scope.data = {
			org_slug: NwuiSession.user.org.slug,
			esignevents: $scope.$parent.data.esignevents,
			accounts: ResolveAccounts,
			active_account: undefined,
			holdings: undefined,
			performance: undefined,
			performance_series: undefined,
			balance_series: undefined,
			allocation: undefined,
			eft_accounts: undefined,
			eft_sources: undefined,
			has_eft_account: undefined,
			supports_eft_source_page: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.transfers &&
				organization_data.port_data.transfers.eft_sources &&
				organization_data.port_data.transfers.eft_sources.create &&
				organization_data.port_data.transfers.eft_sources.create.version === 'page'
			),
			use_transfers_page: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.transfers &&
				organization_data.port_data.transfers.create &&
				organization_data.port_data.transfers.create.version === 'page'
			),
			risk_profile_approval_required: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.transfers &&
				organization_data.port_data.transfers.risk_profile &&
				organization_data.port_data.transfers.risk_profile.approval_required === true
			),
			has_account_create: (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.accounts &&
				organization_data.port_data.accounts.create
			),
			hide_transfers: organization_data.port_data.hide_transfers,
			show_account_performance: lodashGet(organization_data, 'port_data.accounts.show_performance', true)
		}

		$scope.blocks = {
			create: {
				button: new NwuiButtonV3Factory({
					id: 'accounts-view-create-new-button',
					text_i18n: 'header.b.button_account_create',
					size: 'small',
					color: 'warning',
					stretch: true,
					icon: {
						name: 'create'
					},
					onClick: function() {
						// this.accountCreateModalOpen.emit(true);
						$scope.modals.create_account_type_select.open = true;
					}
				})
			},
			left: {
				account_select: {
					form: new NwuiFormV3Factory({
						name: 'account-select-form',
						submit_button: false,
						onSubmit: function(post_data) {
						},
						onComplete: function(obj) {
						},
						steps: [{
							columns: [{
								directive: 'input',
								width: 100,
								data: {
									directive: 'select',
									data: {
										id: 'account-select-form-input',
										// label: 'Switch accounts',
										label_i18n: 'accounts.view.switcher.label',
										model: $stateParams.account_id || '',
										options: ResolveAccounts.map(accountOptionMap),
										// placeholder: 'Select account...',
										placeholder_i18n: 'accounts.view.switcher.placeholder',
										stretch: true,
										size: 'small',
										color: '',
										required: false,
										onChange: function() {
												
											////console.log('AccountsViewController', 'account-select-input-change', NwuiInputService.inputs['account-select-form-input'].model);
											
											var account_id = NwuiInputService.inputs['account-select-form-input'].model;
											if(!account_id || account_id.length == 0 || account_id == $stateParams.account_id) return;

											$state.go('app.accounts.view', { account_id: account_id });
													
										}
									}
								}
							}]
						}]
					})
				},
				buttons: [
					/*new NwuiButtonV3Factory({
						id: 'account-id-button',
						text: 'ABCD123',
						size: 'short',
						//color: 'alternate',
						hollow: true,
						onClick: function() {
							
						}
					})*/
				]
			},
			right: {
				buttons: [
				]
			}
		};

		$scope.modules = {
			time_range_select: {
				form: new NwuiFormV3Factory({
					name: 'account-select-form',
					submit_button: false,
					onSubmit: function(post_data) {
					},
					onComplete: function(obj) {
					},
					steps: [{
						columns: [{
							directive: 'input',
							width: 100,
							data: {
								directive: 'select',
								data: {
									id: 'time-range-select-input',
									label: '',
									model: 4,
									options: NWUI_SELECT_OPTIONS.TIME_RANGES,
									placeholder: 'Select range...',
									stretch: false,
									size: 'small',
									color: 'alternate',
									required: false,
									onChange: function(obj) {
										
										$scope.active_range = $scope.ranges[obj];

										$scope.modules.performance_chart.range = $scope.ranges[obj];
										$scope.modules.balance_chart.range = $scope.ranges[obj];
										
									}
								}
							}
						}]
					}]
				})
			},
			performance_chart: new NwuiChartLineFactory({
				series: undefined,
				units: '%',
				trim_dates: true,
				//height_ratio: 50,
				horiz_axis_labels: true
			}),
			balance_chart: new NwuiChartLineFactory({
				series: undefined,
				units: 'currency',
				trim_dates: true,
				//height_ratio: 50,
				horiz_axis_labels: true
			})
		}

		$scope.compounds = {
			add_eft_source: {
				open: false,
				formResource: EftService.createEft,
				submitCb: function(obj) {
					
					////console.log('AccountsController', 'add_eft_source submitCb', obj);

					loadRecommendation();
					
				}
			},
			create_eft: {
				open: false,
				accounts: ResolveDestinationAccounts.filter(function(account) {
					return account.account_type !== 'All'
				}).map(function(account,i) {
					return {
						id: i,
						// text: account.account_type + ' (' + account.custodian_id + ')',
						text_i18n: 'nwui.common.input.destination_account.option',
						text_i18n_data: {
							accountType: getAccountType(account),
							accountId: account.custodian_id
						},
						value: account.id
					}
				}),
				accounts_efts: [],
				formResource: EftService.submitEftRequest,
				submitCb: function(obj) {
					
					////console.log('AccountsController', 'create_eft submitCb', obj);
					
				}
			}
		}

		EftService.getEftsAccounts().then(function(obj) {
			$scope.compounds.create_eft.accounts_efts = obj.data;
		});

		$scope.cards = {
			empty: {
				button: new NwuiButtonV3Factory({
					// text: 'Back to Summary',
					text_i18n: 'accounts.view.empty.button',
					icon: {
						name: 'arrow_back'
					},
					onClick: function() {
						$state.go('app.summary');
					}
				})
			},
			info: {
				// header: new NwuiCardHeaderV2Factory({
				// 	name: 'Account Information',
				// 	icon: {
				// 		name: 'info'
				// 	}
				// }),
				// form: new NwuiFormV3Factory({
				// 		name: 'account-information-form',
				// 		submit_button: false,
				// 			onSubmit: function(post_data) {
				// 			},
				// 		onComplete: function(obj) {
				// 		},
				// 			steps: [{
				// 		columns: [{
				// 			directive: 'input',
				// 			width: 50,
				// 					data: {
				// 						directive: 'text',
				// 						data: {
				// 							label: 'Acct. Type',
				// 							model: '',
				// 							id: 'account-information-form-input-type',
				// 							stretch: true,
				// 							required: true,
				// 							disabled: true,
				// 							size: 'small',
				// 							placeholder: ""
				// 					}
				// 					}
				// 			}, {
				// 			directive: 'input',
				// 			width: 50,
				// 					data: {
				// 						directive: 'text',
				// 						data: {
				// 							label: 'Acct. Number',
				// 							model: '',
				// 							id: 'account-information-form-input-number',
				// 							stretch: true,
				// 							required: true,
				// 							disabled: true,
				// 							size: 'small',
				// 							placeholder: ""
				// 					}
				// 					}
				// 			}]
				// 	}]
				// 	}),
				recommendation: undefined,
				button: new NwuiButtonV3Factory({
					id: 'info-add-eft-source-button',
					text: 'Loading...',
					color: '',
					size: 'small',
					hollow: true,
					stretch: true,
					onClick: function() {}
				})
			},
			withdrawal: {
				button: new NwuiButtonV3Factory({
					id: 'withdrawal-button',
					text: 'Loading...',
					color: 'success',
					size: 'small',
					hollow: true,
					stretch: true,
					onClick: function() {}
				})
			},
			total_holdings: {
				header: new NwuiCardHeaderV2Factory({
					// name: 'Total Holdings',
					name_i18n: 'accounts.view.holdings.title',
					//level: 2,
					icon: {
						name: 'account_balance_wallet'
					}
				}),
				big_number: new NwuiCardBigNumberFactory({
					filter: 'currency'
				})
			},
			account_information: {
				header: new NwuiCardHeaderV2Factory({
					name_i18n: 'accounts.view.account_information.title',
					icon: {
						name: 'info'
					}
				})
			},
			total_change: {
				header: new NwuiCardHeaderV2Factory({
					// name: 'Total Gains/Losses',
					name_i18n: 'accounts.view.gains_losses.title',
					//level: 2,
					icon: {
						name: 'swap_vertical_circle'
					}
				}),
				big_number: new NwuiCardBigNumberFactory({
					filter: 'NwuiCurrencyWithDelta'
				})
			},
			performance_series: {
				// header: new NwuiCardHeaderV2Factory({
				// 	name: 'Performance',
				// 	icon: {
				// 		name: 'timeline'
				// 	}
				// }),
				chart: new NwuiChartLineFactory({
					series: undefined,
					//height_ratio: 60,
					trim_dates: true,
					units: '%',
					number_filter: 'NwuiPercentWithDelta'
					//x_axis_labels: true
				})
			}
		}

		// ApiAccountsService.getAccounts().then(function(obj) {
		// 	////console.log('AccountsViewController', 'ApiAccountsService.getAccounts', obj.data);
			
		// 	$scope.data.accounts = obj.data;
		// });
		
		$scope.$watch('data.accounts', function(new_val) {
				////console.log('AccountsViewController', 'watch data.accounts', new_val);
				
				if(new_val === undefined) return;

				if (NwuiSession.user.org.slug === 'resolveca') {
					$scope.someAccountsAwaitingResolveCaHistoricalPerformanceIngestion = false;
					for(var i = 0; i < new_val.length; i++) {
						var accountOpenDate;
						if(new_val[i].open_date && moment(new_val[i].open_date).toString() !== 'Invalid date') {
							accountOpenDate = moment(new_val[i].open_date);
						} else {
							accountOpenDate = null;
						}
			
						if(accountOpenDate && accountOpenDate < RESOLVECA_PRE_EXISTING_ACCOUNTS_CUTOFF_DATE) {
							$scope.someAccountsAwaitingResolveCaHistoricalPerformanceIngestion = true;
	
							break;
						}
					}
				}

				var index = 0;
				if($stateParams.account_id) {
		
					$scope.data.accounts.forEach(function(obj, i) {
						if($stateParams.account_id == obj.id)
							index = i;
					});
					
					$scope.data.active_account = $scope.data.accounts[index];
					if(NwuiInputService.inputs['account-select-form-input'])
						NwuiInputService.inputs['account-select-form-input'].options = new_val.map(accountOptionMap)
				} else if($scope.data.accounts.length === 0) {



				} else {
						
					// if(!NwuiOverlayService.overlays['global-overlay']) return;
					// NwuiOverlayService.overlays['global-overlay'].icon.name = "account_balance";
					// NwuiOverlayService.overlays['global-overlay'].message = "Loading Account...";
					// NwuiOverlayService.overlays['global-overlay'].show = true;
						
					$timeout(function() {
						$state.go('app.accounts.view', { account_id: $scope.data.accounts[0].id });
					}, 750);

				}

		});

		$scope.data.accounts = ResolveAccounts;

		$scope.$watch('data.active_account', function(new_val) {
			////console.log('AccountsViewController', 'watch data.active_account', new_val);
			
			if(new_val === undefined) return;

			if (NwuiSession.user.org.slug === 'resolveca') {
				var accountOpenDate;
				if(new_val.open_date && moment(new_val.open_date).toString() !== 'Invalid date') {
					accountOpenDate = moment(new_val.open_date);
				} else {
					accountOpenDate = null;
				}
	
				if(accountOpenDate && accountOpenDate < RESOLVECA_PRE_EXISTING_ACCOUNTS_CUTOFF_DATE) {
					$scope.currentAccountAwaitingResolveCaHistoricalPerformanceIngestion = true;
	
					$scope.historicalPerformanceRecommendation = new NwuiRecommendationFactory({
						type: 'info',
						icon: {
							name: 'info'
						},
						text_i18n: 'nwui.common.line_graph.awaiting_historical_data_message'
					});
				} else {
					$scope.currentAccountAwaitingResolveCaHistoricalPerformanceIngestion = false;
					$scope.historicalPerformanceRecommendation = null;
				}
			}

			loadRecommendation();

			ApiAccountsService.getHoldings(new_val).then(function(obj) {
				////console.log('AccountsViewController', 'ApiAccountsService.getHoldings', obj.data);
				$scope.data.holdings = obj.data;
				
				$scope.cards.total_holdings.big_number.value = $scope.data.holdings.holdings;

				ApiAccountsService.getAllocation(new_val).then(function(obj) {
					////console.log('AccountsViewController', 'ApiAccountsService.getAllocation', obj.data);
					
					var allocation = [];
					for(var asset_class in obj.data) {
						for(var asset_id in obj.data[asset_class].assets) {
							//var asset = angular.copy(obj.data[asset_class]);
							//asset.ticker = obj.data[asset_class].assets[asset_id].ticker;
							obj.data[asset_class].assets[asset_id].allocation = parseFloat(obj.data[asset_class].assets[asset_id].market_value) / $scope.data.holdings.holdings;
							//obj.data[asset_class].assets[asset_id].gains_losses = obj.data[asset_class].gains_losses;
							obj.data[asset_class].assets[asset_id].description = obj.data[asset_class].description;
							obj.data[asset_class].assets[asset_id].i18n_key = setLanguageKey(obj.data[asset_class].description);
							allocation.push(obj.data[asset_class].assets[asset_id]);
						}
					}
					$scope.data.allocation = allocation;
					
					//$scope.cards.total_holdings.big_number.value = $scope.data.holdings.holdings;
				});
			});
			
			ApiAccountsService.getPerformance(new_val).then(function(obj) {
				////console.log('AccountsViewController', 'ApiAccountsService.getPerformance', obj.data);
				$scope.data.performance = obj.data;
				
				$scope.data.performance.periods.forEach(function(period,i) {
					period.name_i18n = 'accounts.view.details.period_' + i;
					period.icon = {
						name: !period.change || period.change.dollar <= 0 ? 'drag_handle' : 'check',
						color: !period.change || period.change.dollar <= 0 ? 'negative' : 'success'
					}
				})

				$scope.cards.total_change.big_number.value = $scope.data.performance.periods[3].change.dollar;
				$scope.cards.total_change.big_number.delta_percent = $scope.data.performance.periods[3].change.percent;
			});
			
			ApiAccountsService.getPerformanceSeries(new_val).then(function(obj) {
				////console.log('AccountsViewController', 'ApiAccountsService.getPerformanceSeries', obj.data);
				$scope.data.performance_series = obj.data;
				
				$scope.cards.performance_series.chart.series = obj.data.series.map(function(item,i) {
					return {
						value: item.close.percent,
						date: item.date
					}
				});

				$scope.modules.performance_chart.series = obj.data.series.map(function(item,i) {
					return {
						value: item.close.percent,
						date: item.date
					}
				});
			});
			
			ApiAccountsService.getBalanceSeries(new_val).then(function(obj) {
				////console.log('AccountsViewController', 'ApiAccountsService.getBalanceSeries', obj.data);
				$scope.data.balance_series = obj.data;

				$scope.data.balance_series.periods.forEach(function(period,i) {
					period.name_i18n = 'accounts.view.details.period_' + i;
					period.icon = {
						name: !period.change || period.change.dollar <= 0 ? 'drag_handle' : 'check',
						color: !period.change || period.change.dollar <= 0 ? 'negative' : 'success'
					}
				});

				$scope.modules.balance_chart.series = obj.data.series.map(function(item,i) {
					return {
						value: item.close.dollar,
						date: item.date
					}
				});
			});
		});
		
		//used to stop an animation before beginning another
		var goal_detail_timeout = undefined;
		
		$scope.goals = null;
		$scope.loaded = false;
		$scope.detail_loaded = false;
		$scope.goal_select_toggle = false;
		$scope.goal_range_toggle = false;
		$scope.goal_detail_selected = 0;
		$scope.goal_detail_style = {};
		$scope.active_goal_id = undefined;
		
		//static time ranges (for v1)
		var date = new Date(moment(ResolveDataAsOf.date));
		
		$scope.ranges = [{
			name: "This Month",
			level: 'day',
			interval: 5,
			date_st: new Date(date.getFullYear(), date.getMonth(), 1),
			date_en: date
		},{
			name: "Last Month",
			level: 'day',
			interval: 5,
			date_st: new Date(date.getFullYear(), date.getMonth()-1, 1),
			date_en: new Date(date.getFullYear(), date.getMonth(), 0)
		},{
			name: "Last 6 Months",
			level: 'month',
			interval: 1,
			date_st: new Date(date.getFullYear(), date.getMonth()-5, 1),
			date_en: date
		},{
			name: "This Year",
			level: 'month',
			interval: 2,
			date_st: new Date(date.getFullYear(), 0, 1),
			date_en: date
		},{
			name: "All time",
			level: 'month',
			interval: 3,
			date_st: new Date(date.getFullYear()-20, 0, 0),
			date_en: date
		}];
		
		//$scope.active_range = $scope.ranges[$scope.ranges.length-1];
		
		//toggle the view detail tab
		$scope.changeDetail = function(number) {
			if (!$scope.data.show_account_performance) {
				number = number - 1;
			}
			
			////console.log('AccountsController', 'changeDetail', number);
			var mod;
			if (!$scope.data.show_account_performance) {
				mod = 2;
			} else {
				mod = $scope.data.holdings ? 3 : 2;
			}
			
			number = (number + mod) % mod;
			
			var height = $('#goals .card.details .content-details .content').eq($scope.goal_detail_selected).height();
			$scope.goal_detail_style = {
				'height': height + 'px'
			};
			
			$timeout(function() {
				$scope.goal_detail_selected = number;
				var height = $('#goals .card.details .content-details .content').eq(number).height();
				$scope.goal_detail_style = {
					'height': height + 'px'
				};
			}, 0);
			
			$timeout.cancel(goal_detail_timeout);
			$timeout(function() {
				$scope.goal_detail_style = {};
			}, 1000);
			
		}
		
		//mobile allocation expand toggle
		$scope.toggleExpanded = function(e, item) {
			
			////console.log(item.expanded, $(e.target).closest('.outer-row').children('.nw-row'));
			
			if(!item.expanded) {
				item.expanded = true;
				item.style = {
					height: $(e.target).closest('.outer-row').children('.nw-row').height() + 'px'
				}
			} else {
				delete item.expanded;
				delete item.style;
			}
				
		}
		
		//set the date ranges based on the last business day close (not current date)
		var setRanges = function(new_date) {
			var new_date = new Date(new_date);
			var diff = Math.abs(new_date.getTime() - date.getTime());
			var day_diff = Math.ceil(diff / (1000 * 3600 * 24));
			var month_diff = 0;
			
			//if day kicks back to previous month, adjust month
			if(date.getDate() - day_diff <= 0)
				month_diff = 1;
			
			//update ranges
			$scope.ranges[0].date_st = new Date(date.getFullYear(), date.getMonth()-month_diff, 1)
			$scope.ranges[1].date_st = new Date(date.getFullYear(), date.getMonth()-1-month_diff, 1)
			$scope.ranges[1].date_en = new Date(date.getFullYear(), date.getMonth()-month_diff, 0)
			$scope.ranges[2].date_st = new Date(date.getFullYear(), date.getMonth()-5-month_diff, 1)
		}
		
		$timeout(function() {
			$scope.loaded = true;
		}, 0);

		if($stateParams.reload === true) {
			ApiAccountsService.getAccountsWithAggregate(true).then(function(obj) {
				$scope.data.accounts = obj.data;
			});
		}

	}
]);