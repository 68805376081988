angular.module('portfolio_web.manage.risk_profiles.list.controllers', [])

.controller('ManageRiskProfilesListController', [
	'$q',
	'$scope', 
	'$state',
	'$timeout',
	'$filter',
	'locale',
	'NwuiCardHeaderV2Factory',
	'NwuiAccordionFactory',
	'NwuiCardTableFactory',
	'NwuiAllocationFactory',
	
	'ApiGoalsService',
	'RiskProfileService',
	'NwButtonService',
	
	'DATE_FORMAT',
	function(
		$q,
		$scope, 
		$state,
		$timeout,
		$filter,
		locale,
		NwuiCardHeaderV2Factory,
		NwuiAccordionFactory,
		NwuiCardTableFactory,
		NwuiAllocationFactory,
		
		ApiGoalsService,
		RiskProfileService,
		NwButtonService,
		
		DATE_FORMAT
	) {
	
		////console.log('ManageRiskProfilesListController Loaded');
		
		$scope.blocks = {
			breadcrumbs: {
				root: {
					icon: {
						name: 'home'
					},
					state: 'app.summary'
				}
			}
		}
		
		$scope.data = {
			goals: undefined
		}
		
		$scope.cards = {
			risk_profiles: {
				header: {
					// name: 'Risk Profiles',
					name_i18n: 'manage.risk_profiles.list.about.title',
	        icon: {
		        name: 'info_outline'
	        }
				},
				goals: []
			}
		}
		
		ApiGoalsService.getGoals().then(function(res) {
			////console.log(res)
			
			$scope.data.goals = res.data;
			
			var promises = [];
			
			$scope.data.goals.forEach(function(goal,i) {
				$scope.cards.risk_profiles.goals.push({
					sub_header: new NwuiCardHeaderV2Factory({
						name: goal.localize ? locale.getString(goal.description) : goal.description,
						icon: {
							name: $filter('NwuiIconAutoPicker')(goal.description, 'data_usage')
						},
						level: 2,
						// button: {
						// 	processing: false,
						// 	id: 'manage-risk-profiles-view-button-' + i,
						// 	// text: 'View Details',
						// 	text_i18n: 'manage.risk_profiles.list.allocation.button',
						// 	icon: {
						// 		name: 'tune'
						// 	},
						// 	onClick: function() {
						// 		NwButtonService.buttons['manage-risk-profiles-view-button-' + i].processing = true;
						// 		$state.go('app.manage.risk_profiles.view', { goal_id: $scope.data.goals[i].id });
						// 	}
						// }
					}),
					accordion: new NwuiAccordionFactory({
						name: goal.localize ? locale.getString(goal.description) : goal.description,
						color: 'alternate',
						expanded: (i==0),
						button: {
							processing: false,
							id: 'manage-risk-profiles-view-button-' + i,
							text_i18n: 'manage.risk_profiles.list.allocation.button',
							onClick: function() {
								NwButtonService.buttons['manage-risk-profiles-view-button-' + i].processing = true;
								$state.go('app.manage.risk_profiles.view', { goal_id: $scope.data.goals[i].id });
							}
						}
					}),
					allocation: new NwuiAllocationFactory({
						series: [],
						decimal: 0
					})
				})
				
				promises.push(RiskProfileService.getRiskProfile(goal.id));
			});
			
			$q.all(promises).then(function(risk_profiles) {
				
				risk_profiles.forEach(function(risk_profile_raw,j) {
					var risk_profile = risk_profile_raw.risk_profiles.current;
					////console.log('ManageRiskProfilesListController', 'risk_profile', risk_profile);
					if(risk_profile) {
						$scope.cards.risk_profiles.goals[j].allocation.series = risk_profile.pie_data.data.map(function(datum,i) {
					    return {
						    name: datum.description,
								percent: datum.percent,
								type: (datum.description.toLowerCase().includes('alternative')) ? 'alternatives' : datum.type,
								percent_range: datum.assetRanges ? datum.assetRanges : null,
								hide: datum.hide ? datum.hide : false
					    }
				    });

				    $scope.cards.risk_profiles.goals[j].sub_header.button = {
				    	id: 'manage-risk-profiles-view-button-' + j,
				    	text_i18n: risk_profile_raw.locked === false ? 'manage.risk_profiles.list.allocation.button_unlocked' : 'manage.risk_profiles.list.allocation.button',
							icon: {
								name: risk_profile_raw.locked === false ? 'assignment_late' : 'tune'
							},
							color: risk_profile_raw.locked === false ? 'warning' : '',
							onClick: function() {
								this.processing = true;
								$state.go('app.manage.risk_profiles.view', { goal_id: $scope.data.goals[j].id });
							}
				    }
						
						////console.log('ManageRiskProfilesListController', 'series', $scope.cards.risk_profiles.goals[j].allocation.series);
						
					}
					
				});
				
			});
		});
	}
]);