angular.module('portfolio_web.api.general.services', [])

.service('ApiGeneralService', [
	'$rootScope', 
	'$http', 
	'$q', 
	'$timeout', 
	'$stateParams',
	'NwuiSession', 
	'PORTFOLIO_WEBSERVER',
	'CacheService',
	function(
		$rootScope, 
		$http, 
		$q, 
		$timeout, 
		$stateParams,
		NwuiSession,
		PORTFOLIO_WEBSERVER,
		CacheService
	) {
		
		var self = this;

		this.getDataAsOf = function(force) {

			var deferred = $q.defer()

			if(cache.get('getDataAsOf') && force !== true)
				deferred.resolve(cache.get('getDataAsOf'));
			else {
				$http.get(PORTFOLIO_WEBSERVER.app + '/api/data/valid-as-of-date').then(function(obj){
					cache.set('getDataAsOf', obj);
					deferred.resolve(obj)
				}, function(err){
					////console.log(err)
					deferred.reject(err)
				})
			}

			return deferred.promise
			
		}

		this.setLanguage = function(to_lang) {

			return $http.get(PORTFOLIO_WEBSERVER.app + '/api/language?language=' + to_lang)
			
		}

		this.getFormQuestions = function() {
			
			var deferred = $q.defer()
	
			$http.get(PORTFOLIO_WEBSERVER.app + '/api/form_questions').then(function(obj){
				deferred.resolve(obj)
			}, function(err){
				deferred.reject(err)
			})
	
			return deferred.promise
			
		}

		this.createAccessCode = function() {
	
			return $http.post(PORTFOLIO_WEBSERVER.app + '/api/access-codes', {
				type: 'UserInvitation'
			});
			
		}

		this.setAgreedToBill64 = function(agreedToBill64) {
			$rootScope.agreedToBill64 = agreedToBill64;
		}

		this.getAgreedToBill64 = function() {
			return $rootScope.agreedToBill64 || false;
		}

		this.setAgreedToBill64ExchangingInfoValue = function(agreedToBill64ExchangingInfoValue) {
			$rootScope.agreedToBill64ExchangingInfoValue = agreedToBill64ExchangingInfoValue;
		}

		this.getAgreedToBill64ExchangingInfoValue = function() {
			return $rootScope.agreedToBill64ExchangingInfoValue || false;
		}

		var cache = CacheService.register(this);

	}
]);