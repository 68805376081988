angular.module('portfolio_web.transfers.new.type.destination.form.controllers', [])

	.controller('TransfersNewTypeDestinationFormController', [
		'DATE_FORMAT',
		'NWUI_SELECT_OPTIONS',
		'$q',
		'$scope',
		'$state',
		'$timeout',
		'$filter',
		'$rootScope',
		'$stateParams',

		'locale',
		'NwuiSession',
		'ApiTransfersService',
		'NwuiToastManagerService',
		'NwuiSigningService',
		'NwuiInputService',
		'NwuiFormV3Service',
		'NwButtonService',
		'EftService',
		'NwuiPageScrollService',

		'NwuiFormV3Factory',
		'NwuiCardHeaderV2Factory',
		'NwuiToastFactory',
		'NwuiModalV2Factory',
		'NwuiButtonV3Factory',

		'ResolveEftAccountOptions',
		'ResolveAccounts',
		'NwuiCardTableFactory',
		'ResolveDestinationAccounts',
		'NwuiRegisterService',
		function (
			DATE_FORMAT,
			NWUI_SELECT_OPTIONS,
			$q,
			$scope,
			$state,
			$timeout,
			$filter,
			$rootScope,
			$stateParams,

			locale,
			NwuiSession,
			ApiTransfersService,
			NwuiToastManagerService,
			NwuiSigningService,
			NwuiInputService,
			NwuiFormV3Service,
			NwButtonService,
			EftService,
			NwuiPageScrollService,

			NwuiFormV3Factory,
			NwuiCardHeaderV2Factory,
			NwuiToastFactory,
			NwuiModalV2Factory,
			NwuiButtonV3Factory,

			ResolveEftAccountOptions,
			ResolveAccounts,
			NwuiCardTableFactory,
			ResolveDestinationAccounts,
			NwuiRegisterService
		) {
			let optionalBTStament = '';
			$scope.maxNumberOfPartialTransferRows = (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.transfers &&
				organization_data.port_data.transfers.brokerage_transfers &&
				organization_data.port_data.transfers.brokerage_transfers.num_of_partial_assets
			) ? organization_data.port_data.transfers.brokerage_transfers.num_of_partial_assets : 1;

			$scope.isPartialBrokerageTransferAllowed = (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.transfers &&
				organization_data.port_data.transfers.brokerage_transfers &&
				organization_data.port_data.transfers.brokerage_transfers.enable_partial_bt === true);

			$scope.isMoreTransferTypesAllowed = (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.transfers &&
				organization_data.port_data.transfers.brokerage_transfers &&
				organization_data.port_data.transfers.brokerage_transfers.add_more_transfer_types === true);

			$scope.partialAssetTypes = (
				organization_data &&
				organization_data.port_data &&
				organization_data.port_data.transfers &&
				organization_data.port_data.transfers.brokerage_transfers &&
				organization_data.port_data.transfers.brokerage_transfers.asset_types
			) ? organization_data.port_data.transfers.brokerage_transfers.asset_types : [];

			$scope.partialTransferAssetData = [];
			$scope.isPartialBrokerageTransfer = false;

			$scope.data = {
				isHideHyperlink: (
					organization_data &&
					organization_data.port_data &&
					organization_data.port_data.hide_email_hyperlink),
				is_registered: false,
				is_eft: $state.params.transfer_type === 'chequing-savings',
				transfer_type: $state.params.transfer_type,
				supports_eft_source_page: (
					organization_data &&
					organization_data.port_data &&
					organization_data.port_data.transfers &&
					organization_data.port_data.transfers.eft_sources &&
					organization_data.port_data.transfers.eft_sources.create &&
					organization_data.port_data.transfers.eft_sources.create.version === 'page'
				),
				risk_profile_approval_required: (
					organization_data &&
					organization_data.port_data &&
					organization_data.port_data.transfers &&
					organization_data.port_data.transfers.risk_profile &&
					organization_data.port_data.transfers.risk_profile.approval_required === true
				),
				show_terms_link: (
					organization_data &&
					organization_data.port_data &&
					organization_data.port_data.transfers &&
					organization_data.port_data.transfers.efts &&
					organization_data.port_data.transfers.efts.show_terms_link === true
				),
				check_leveraged_position_confirmation: (
					organization_data &&
					organization_data.port_data &&
					organization_data.port_data.transfers &&
					organization_data.port_data.transfers.check_leveraged_position_confirmation === true
				),
				recurring_block_days_count: (
					organization_data &&
						organization_data.port_data &&
						organization_data.port_data.transfers &&
						organization_data.port_data.transfers.efts &&
						organization_data.port_data.transfers.efts.recurring &&
						organization_data.port_data.transfers.efts.recurring.block_start_days ? organization_data.port_data.transfers.efts.recurring.block_start_days : 0
				),
				one_time_block_days_count: (
					organization_data &&
						organization_data.port_data &&
						organization_data.port_data.transfers &&
						organization_data.port_data.transfers.efts &&
						organization_data.port_data.transfers.efts.one_time &&
						organization_data.port_data.transfers.efts.one_time.block_start_days ? organization_data.port_data.transfers.efts.one_time.block_start_days : 0
				),
				show_fiscal_impacts_info_card: (
					organization_data &&
					organization_data.port_data &&
					organization_data.port_data.transfers &&
					organization_data.port_data.transfers.brokerage_transfers &&
					organization_data.port_data.transfers.brokerage_transfers.show_fiscal_impacts_info_card === true
				),
				filter_temporary_account_numbers: (
					organization_data &&
					organization_data.port_data &&
					organization_data.port_data.transfers &&
					organization_data.port_data.transfers.brokerage_transfers &&
					organization_data.port_data.transfers.brokerage_transfers.filter_temporary_account_numbers === true
				),
				openChat: function (e) {
					e.stopPropagation();
					e.preventDefault();

					if (window.Intercom) {
						window.Intercom('show');
					} else {
						var contactArr = organization_data.footer.contact;
						var urlInfo = contactArr.filter(contactInfo => contactInfo.type === "email");
						window.location.href = `mailto:${urlInfo[0].data.id}`;
					}

				}
			}

			ResolveDestinationAccounts.forEach(function (account, i) {
				$scope.custodian_slug = account.custodian_slug;
				if (account.id !== $stateParams.account_id) return;

				$scope.data.is_registered = (account.accountType && account.accountType.is_registered === true);

			})

			var checkIfTemporaryAccountNumber = function () {
				if ($scope.data.transfer_type === 'investment'
					&& $scope.data.filter_temporary_account_numbers
					&& ResolveAccounts.length === 1) {
					if (ResolveAccounts[0].open_date == null) {
						$scope.data.is_temporary_account_number = true;
					}
				} else {
					$scope.data.is_temporary_account_number = false;
				}
			}

			checkIfTemporaryAccountNumber();

			function onTransferSubmit() {
				var scopeSubmitButton = $scope.card.buttons.submit;
				var submitButton = NwButtonService[$scope.card.buttons.submit.id];

				if (!submitButton) {
					NwButtonService.register(scopeSubmitButton);
				}

				if (NwButtonService.buttons[scopeSubmitButton.id].processing) {
					return false;
				}

				var formName =
					$scope.data.transfer_type === 'investment'
						? $scope.card.forms.bt.name
						: $scope.card.forms.eft.name;

				NwButtonService.buttons[scopeSubmitButton.id].processing = true;
				NwuiFormV3Service.submit(formName);
			}

			var SubmitButton = {
				id: ('transfers-new-form-submit-button' + NwuiRegisterService.register()),
				text_i18n: 'nwui.common.form.button.submit',
				color: 'warning',
				icon: {
					name: 'check'
				},
				disabled: (
					$scope.data.check_leveraged_position_confirmation ?
						true :
						($scope.data.is_registered && $scope.data.is_eft)
				),
				onClick: onTransferSubmit
			};

			$scope.contribution_note = locale.getString('transfers.new.contribution_note.p1') === '%%KEY_EMPTY%%' || locale.getString('transfers.new.contribution_note.p1') === '' ? false : true;
			$scope.card = {
				header: new NwuiCardHeaderV2Factory({
					name_i18n: $scope.data.transfer_type === 'investment' ? "transfers.new.investment.form.header" : "transfers.new.chequing_savings.form.header",
					level: 2,
					icon: {
						name: $scope.data.risk_profile_approval_required ? 'looks_3' : 'looks_two'
					}
				}),
				forms: {
					bt: new NwuiFormV3Factory({
						name: 'transfers-new-create-brokerage-transfer-new-destination-form',
						submit_button: false,
						onSubmit: function (post_data) {
							NwButtonService.buttons[$scope.card.buttons.submit.id].processing = true;

							post_data.account_id = $state.params.account_id;

							$scope.partialTransferAssetData.forEach(function (asset, index) {
								if (asset.share_count === '')
									$scope.partialTransferAssetData[index].share_count = 0;
								if (asset.amount === '')
									$scope.partialTransferAssetData[index].amount = 0;
							})

							if ($scope.partialTransferAssetData.length > 0) {
								post_data.assets = $scope.partialTransferAssetData;
							}

							if ($scope.data.is_registered) {
								post_data.confirmed_contribution_room = true;
							}

							if ($scope.data.check_leveraged_position_confirmation) {
								post_data.confirmed_not_leveraged_position = true
							}

							if (optionalBTStament !== '') {
								post_data.optional_bt_statement = optionalBTStament;
							}

							return ApiTransfersService.createBrokerageTransfer(post_data, NwuiSession.user.client_id);

						},
						onComplete: function (obj) {
							NwButtonService.buttons[$scope.card.buttons.submit.id].processing = false;

							NwuiToastManagerService.register(
								new NwuiToastFactory({
									text_i18n: 'transfers.modal.bt.success',
									icon: {
										name: 'check'
									}
								}
								));

							if (obj.data.envelope_id) {
								$timeout(function () {
									NwuiSigningService.setEnvelope('global-document-signing', obj.data.envelope_id);
									NwuiSigningService.setSubmitCb('global-document-signing', function (obj) {

										$state.go('app.transfers.overview');

									});

									NwuiPageScrollService.open('global-document-signing');
								}, 0);
							} else {
								$state.go('app.transfers.overview');
							}

						},
						onError: function (error) {
							NwButtonService.buttons[$scope.card.buttons.submit.id].processing = false;
							if (!error.data) return;

							var error_msg = '';
							if (error.data.code) {
								error_msg = 'nwui.common.server_errors.' + error.data.code;
							} else {
								error_msg = 'nwui.modal.create_eft.error';
							}

							NwuiToastManagerService.register(
								new NwuiToastFactory({
									text_i18n: error_msg,
									type: 'error',
									icon: {
										name: 'close'
									}
								}
								));
							$state.go('app.transfers.overview');

						},
						steps: [
							{
								columns: [{
									directive: 'input',
									width: 60,
									data: {
										directive: 'text',
										data: {
											label_i18n: 'transfers.input.source_institution.label',
											model: '',
											id: 'create-brokerage-transfer-form-input-financial-institution',
											stretch: true,
											required: true,
											size: '',
											post_var: 'financial_institution',
											placeholder_i18n: 'nwui.common.input.institution_name.placeholder'
										}
									}
								}, {
									directive: 'input',
									width: 40,
									data: {
										directive: 'text',
										data: {
											label_i18n: 'transfers.input.source_account_number.label',
											model: '',
											id: 'create-brokerage-transfer-form-input-account-number',
											stretch: true,
											required: true,
											size: '',
											post_var: 'account_number',
											placeholder_i18n: 'transfers.input.source_account_number.placeholder'
										}
									}
								}, {
									directive: 'input',
									width: 65,
									data: {
										directive: 'text',
										data: {
											label_i18n: 'transfers.input.source_address.label',
											model: '',
											id: 'create-brokerage-transfer-form-input-financial-institution-address',
											stretch: true,
											required: true,
											post_var: 'street_address',
											placeholder_i18n: 'nwui.common.input.address.placeholder'
										}
									}
								}, {
									directive: 'input',
									width: 35,
									data: {
										directive: 'text',
										data: {
											label_i18n: 'nwui.common.input.city.label',
											model: '',
											id: 'create-brokerage-transfer-form-input-city',
											stretch: true,
											required: true,
											post_var: 'city',
											placeholder_i18n: 'nwui.common.input.city.placeholder'
										}
									}
								}, {
									directive: 'input',
									width: 55,
									data: {
										directive: 'select',
										data: {
											label_i18n: 'nwui.common.input.province.label',
											model: '',
											id: 'create-brokerage-transfer-form-input-province',
											stretch: true,
											required: true,
											post_var: 'province',
											options: NWUI_SELECT_OPTIONS.PROVINCES,
											placeholder_i18n: 'nwui.common.input.province.placeholder'
										}
									}
								}, {
									directive: 'input',
									width: 45,
									data: {
										directive: 'text',
										data: {
											label_i18n: 'nwui.common.input.postal_code.label',
											model: '',
											id: 'create-brokerage-transfer-form-input-postal',
											stretch: true,
											required: true,
											mask: 'postal',
											validators: ['postal'],
											post_var: 'postal_code',
											placeholder_i18n: 'nwui.common.input.postal_code.placeholder'
										}
									}
								}, {
									directive: 'input',
									width: 100,
									data: {
										directive: 'upload',
										data: {
											id: 'create-brokerage-transfer-form-input-upload-file',
											label_i18n: 'transfers.input.upload.label',
											sub_label_i18n: 'transfers.new.investment.form.upload_label',
											model: '',
											url: '/api/documents',
											placeholder_i18n: 'nwui.common.input.file_upload.placeholder',
											required: false,
											onSuccess: function (item) {
												optionalBTStament = item.data.file_name;
												NwuiToastManagerService.register(
													new NwuiToastFactory({
														text_i18n: 'nwui.common.toast.document_upload',
														icon: {
															name: 'check'
														}
													}
													));
											}
										}
									}
								}, {
									directive: 'input',
									width: 100,
									hidden: !$scope.isPartialBrokerageTransferAllowed,
									data: {
										directive: 'radio',
										data: {
											id: 'create-brokerage-transfer-form-input-checkbox',
											stretch: true,
											required: false,
											default_value: 'full',
											type: 'stacked',
											options: transferTypeOptions($scope.isMoreTransferTypesAllowed),
											size: '',
											post_var: 'type',
											onChange: function (inputVal) {
												if (inputVal === 'full' || inputVal === 'fullinkind') {
													togglePartialBrokerageTransfer(false);
												} else if (inputVal === 'partial' || inputVal === 'fullmixed') {
													togglePartialBrokerageTransfer(true);
												}
											}
										}
									}
								}]
							}
						]
					}),
					eft: new NwuiFormV3Factory({
						name: 'transfers-new-create-eft-new-destination-form',
						submit_button: false,
						onSubmit: function (post_data) {
							post_data.request_type = 'deposit';
							NwButtonService.buttons[$scope.card.buttons.submit.id].processing = true;

							if ($scope.data.is_registered) {
								post_data.confirmed_contribution_room = true;
							}

							if ($scope.data.check_leveraged_position_confirmation) {
								post_data.confirmed_not_leveraged_position = true;
							}

							return EftService.submitEftRequest(post_data);
						},
						onComplete: function (obj) {
							NwButtonService.buttons[$scope.card.buttons.submit.id].processing = false;

							var isEftCancelled = obj.data && obj.data.cancelled && obj.data.cancelled_date;

							if (obj.data.error || isEftCancelled) {
								NwuiToastManagerService.register(
									new NwuiToastFactory({
										text_i18n: 'nwui.modal.create_eft.error',
										type: 'error',
										icon: {
											name: 'close'
										}
									}
									));
								$state.go('app.transfers.overview');
							} else {
								NwuiToastManagerService.register(
									new NwuiToastFactory({
										text_i18n: 'nwui.modal.create_eft.success',
										icon: {
											name: 'check'
										}
									}
									));

								if (obj.data.envelope_id) {
									$timeout(function () {
										NwuiSigningService.setEnvelope('global-document-signing', obj.data.envelope_id);
										NwuiSigningService.setSubmitCb('global-document-signing', function (obj2) {
											if (obj2.is_signed) {
												$state.go('app.transfers.overview');
											}
										});

										NwuiPageScrollService.open('global-document-signing');
									}, 0);

								} else {
									$state.go('app.transfers.overview');
								}

							}

						},
						onError: function (error) {

							NwButtonService.buttons[$scope.card.buttons.submit.id].processing = false;

							if (typeof error.data === 'undefined')
								return;
							var text_i18n = undefined;

							switch (error.data.code) {
								case 4810:
									text_i18n = 'transfers.new.chequing_savings.error.4810';
									break;
								case 4813:
									text_i18n = 'transfers.new.chequing_savings.error.4813';
									break;
								case 4318:
									text_i18n = 'transfers.new.chequing_savings.error.4318';
									$('html, body').animate({ scrollTop: $("#app-transfers-new-type-destination").offset().top - 100 }, 500);
									break;
								default:
									text_i18n = 'nwui.modal.create_eft.error';
							}

							if (error.data.fields) {
								var errorKey = Object.keys(error.data.fields)[0];
								switch (errorKey) {
									case 'eft_amount_start_frequency':
										text_i18n = 'transfers.new.chequing_savings.error.eft_amount_start_frequency';
										break;
									default:
										text_i18n = 'nwui.modal.create_eft.error';
								}
							}

							if (text_i18n) {
								NwuiToastManagerService.register(
									new NwuiToastFactory({
										text_i18n: text_i18n,
										type: 'error',
										icon: {
											name: 'close'
										}
									}
									));
							}
						},
						steps: [{
							description_i18n: 'nwui.modal.create_eft.disclaimer',
							columns: [{
								directive: 'input',
								width: 100,
								data: {
									directive: 'select',
									data: {
										label_i18n: 'nwui.common.input.source_account.label',
										model: '',
										id: 'transfers-new-create-eft-form-input-from-account',
										stretch: true,
										required: true,
										placeholder_i18n: 'nwui.common.input.source_account.placeholder',
										options: ResolveEftAccountOptions,
										post_var: 'from_account',
										onChange: function (val) {

											if (val === 'add-new') {
												if ($scope.data.supports_eft_source_page) {
													$state.go('app.manage.transfer_sources.list', { redirectAddNew: true });
												} else {
													$state.go('app.manage.transfer_sources.list', { modal: true });
												}
											}

										}
									}
								}
							}, {
								directive: 'input',
								width: 40,
								data: {
									directive: 'select',
									data: {
										label_i18n: 'nwui.common.input.frequency.label',
										model: '',
										id: 'transfers-new-create-eft-form-input-frequency',
										stretch: true,
										required: true,
										placeholder_i18n: 'nwui.common.input.frequency.placeholder',
										post_var: 'frequency',
										options: organization_data.port_data.transfers.efts.frequency.map(function (f) {
											return {
												id: f.value,
												value: f.name,
												text_i18n: 'nwui.common.form.select.option.frequency.' + f.slug
											}
										}),
										onChange: function (val) {

											if (!NwuiInputService.inputs['create-eft-form-input-date-start']) return;
									
											if ($scope.custodian_slug === 'fcc' && val === 'one time') {
												NwuiInputService.inputs['create-eft-form-input-date-start'].disabled = true;
												NwuiInputService.inputs['create-eft-form-input-date-start'].label_i18n = 'nwui.common.input.date_start.label_ad_hoc';
												NwuiInputService.inputs['create-eft-form-input-date-start'].model = moment().add(1, 'day').format(DATE_FORMAT.digit);
												NwuiInputService.inputs['create-eft-form-input-date-start'].model_text = moment().add(1, 'day').format('YYYY/MM/DD');
												NwuiInputService.inputs['create-eft-form-input-date-start'].validators = ['date_future'];
											} else {
												NwuiInputService.inputs['create-eft-form-input-date-start'].disabled = false;
												NwuiInputService.inputs['create-eft-form-input-date-start'].label_i18n = 'nwui.common.input.date_start.label';
												NwuiInputService.inputs['create-eft-form-input-date-start'].validators = ['date_future_in_businessdays'];
											}

											delete NwuiInputService.inputs['transfers-new-create-eft-form-input-amount'].notification;
											NwuiInputService.inputs['transfers-new-create-eft-form-input-amount'].error = false;
											NwuiInputService.inputs['transfers-new-create-eft-form-input-amount'].model = '';
											if (
												organization_data &&
												organization_data.port_data &&
												organization_data.port_data.transfers &&
												organization_data.port_data.transfers.efts &&
												organization_data.port_data.transfers.efts.disable_one_time_execution_date
											) {
												NwuiInputService.inputs['create-eft-form-input-date-start'].hide = (val === 'one time')
											}

											if (
												organization_data &&
												organization_data.port_data &&
												organization_data.port_data.transfers &&
												organization_data.port_data.transfers.efts &&
												organization_data.port_data.transfers.efts.min_dollar_amount && val === 'one time'
											) {
												NwuiInputService.inputs['transfers-new-create-eft-form-input-amount'].min_val = organization_data.port_data.transfers.efts.min_dollar_amount;
											}
											else if (
												organization_data &&
												organization_data.port_data &&
												organization_data.port_data.transfers &&
												organization_data.port_data.transfers.efts &&
												organization_data.port_data.transfers.efts.recurring_min_amount && val !== 'one time'
											) {
												NwuiInputService.inputs['transfers-new-create-eft-form-input-amount'].min_val = organization_data.port_data.transfers.efts.recurring_min_amount;
											}
											if (organization_data.port_data.transfers.efts.validations.startdate_plusbusinessdays > 0) {
												NwuiInputService.inputs['create-eft-form-input-date-start'].validators = (val == 'one time') ? ['date_future'] : ['date_future_in_businessdays'];
											} else if (val === 'one time' && $scope.data.one_time_block_days_count > 0) {
												NwuiInputService.inputs['create-eft-form-input-date-start'].validators = ['date_future_plus_' + $scope.data.one_time_block_days_count]
											} else if (val !== 'one time' && $scope.data.recurring_block_days_count > 0) {
												NwuiInputService.inputs['create-eft-form-input-date-start'].validators = ['date_future_plus_' + $scope.data.recurring_block_days_count]
											} else {
												NwuiInputService.inputs['create-eft-form-input-date-start'].validators = ['date_future']
											}

											if (($scope.custodian_slug !== 'fcc' || val !== 'one time')) {
												NwuiInputService.inputs['create-eft-form-input-date-start'].model = '';
											}

											NwuiInputService.inputs['create-eft-form-input-date-start'].label_i18n = (val === 'one time')
												? 'nwui.common.input.date_start.label_ad_hoc'
												: 'nwui.common.input.date_start.label';

										}
									}
								}
							}, {
								directive: 'input',
								width: 30,
								data: {
									directive: 'text',
									data: {
										label_i18n: 'nwui.common.input.amount.label',
										model: '',
										id: 'transfers-new-create-eft-form-input-amount',
										stretch: true,
										mask: 'currency_decimal',
										validators: ['eft_max_amount'],
										required: true,
										post_var: 'amount',
										placeholder_i18n: 'nwui.common.input.amount.placeholder'
									}
								}
							}, {
								directive: 'input',
								width: 30,
								data: {
									directive: 'text',
									data: {
										label_i18n: 'nwui.common.input.date_start.label',
										model: '',
										id: 'create-eft-form-input-date-start',
										stretch: true,
										mask: 'date_dashed',
										validators: ['date_future'],
										required: true,
										post_var: 'start_date',
										placeholder: DATE_FORMAT.short,
										onChange: function (model) {
											if ($scope.custodian_slug === 'fcc' && NwuiInputService.inputs['transfers-new-create-eft-form-input-frequency'].model === 'one time') {
												NwuiInputService.inputs['create-eft-form-input-date-start'].disabled = true;
												NwuiInputService.inputs['create-eft-form-input-date-start'].label_i18n = 'nwui.common.input.date_start.label_ad_hoc';
												NwuiInputService.inputs['create-eft-form-input-date-start'].model = moment().add(1, 'day').format(DATE_FORMAT.digit);
												NwuiInputService.inputs['create-eft-form-input-date-start'].model_text = moment().add(1, 'day').format('YYYY/MM/DD');
												NwuiInputService.inputs['create-eft-form-input-date-start'].validators = ['date_future'];
											}
										},
									}
								}
							}, {
								hidden: !$scope.data.show_terms_link,
								directive: 'input',
								width: 100,
								data: {
									directive: 'checkbox',
									data: {
										hide: !$scope.data.show_terms_link,
										id: 'transfers-new-create-eft-form-input-confirmations',
										stretch: true,
										required: true,
										options: [{
											label_i18n: 'nwui.common.input.eft_terms_and_conditions.check_0',
											model: "terms_and_conditions",
											value: 0,
											required: true
										}],
										size: '',
										post_var: 'user_confirmations'
									}
								}
							}]
						}]
					})
				},
				recommendations: {
					bt: {
						type: '',
						icon: {
							name: 'check_circle',
							color: 'success'
						},
						text_i18n: 'transfers.new.investment.form.recommendation',
						text_i18n_data: {
							orgNameShort: organization_data.name_short
						},
						button: undefined
					},
					eft: {
						type: '',
						icon: {
							name: 'error_outline',
							color: 'warning'
						},
						text: "Please review and acknowledge the <a ng-click='textFunction();'>Electronic Transfer (EFT) Terms and Conditions</a> before submitting your funds transfer request.",
						textFunction: function () {
							$scope.modal.eft_terms.open = true;
						},
						button: undefined
					}
				},
				buttons: {
					submit: SubmitButton
				}
			}

			$scope.modal = {
				eft_terms: new NwuiModalV2Factory({
					open: false,
					wide: true,
					header: new NwuiCardHeaderV2Factory({
						name_i18n: 'nwui.modal.eft_terms.title',
						icon: {
							name: 'assignment'
						}
					}),
					buttons: {
						close: new NwuiButtonV3Factory({
							id: 'eft-terms-button-close',
							text_i18n: 'nwui.common.modal.eft_terms.button',
							color: '',
							icon: {
								name: 'undo'
							},
							stretch: true,
							onClick: function () {
								$scope.modal.eft_terms.open = false;
							}
						})
					}
				})
			}

			$scope.partialAssetsTransferHeader = new NwuiCardHeaderV2Factory({
				name_i18n: "transfers.new.investment.form.partial_asset_header",
				level: 2,
				icon: {
					name: $scope.data.risk_profile_approval_required ? 'looks_4' : 'looks_3'
				}
			})

			$scope.registeredConfirmCard = {
				header: {
					name_i18n: "transfers.new.registered_confirm.header",
					level: 2,
					icon: {
						name: 'info'
					}
				},
				form: new NwuiFormV3Factory({
					name: 'registered-confirm-form',
					onSubmit: function (post_data) {
					},
					onComplete: function (obj) {
					},
					submit_button: false,
					steps: [
						{
							columns: [{
								directive: 'input',
								width: 100,
								data: {
									directive: 'checkbox',
									data: {
										id: 'registered-confirm-form-input-check',
										label: "",
										stretch: true,
										required: true,
										options: [{
											label_i18n: "transfers.new.registered_confirm.checkbox",
											model: 'approve',
											value: false,
											required: true
										}],
										size: '',
										post_var: 'registered',
										onChange: function (val) {
											var isButtonActive = false;

											var isInvalid = function (data) {
												return data == undefined || data == ''
											}

											if ($scope.data.check_leveraged_position_confirmation) {
												var leveragedInput = NwuiInputService.inputs['leveraged-confirm-form-input-check'].model;

												if (isInvalid(val) || isInvalid(leveragedInput))
													return false;

												isButtonActive = val[0].value === true && leveragedInput[0].value === true;

											} else {
												if (val == undefined || val == '')
													return false;

												isButtonActive = val[0].value === true;
											}
											if (isButtonActive) {
												SubmitButton.disabled = false;
											} else {
												NwButtonService.buttons[$scope.card.buttons.submit.id].disabled = true;
											}
										}
									}
								}
							}]
						}
					]
				})
			}

			$scope.fiscalImpactsInfoCard = {
				header: {
					name_i18n: "transfers.new.fiscal_impacts.header",
					level: 2,
					icon: {
						name: 'info'
					}
				},
				isVisible: !$scope.data.is_registered && $scope.data.show_fiscal_impacts_info_card && !$scope.data.is_eft,
			}

			$scope.leveragedConfirmCard = {
				header: {
					name_i18n: "transfers.new.leveraged_confirm.header",
					level: 2,
					icon: {
						name: 'info'
					}
				},
				form: new NwuiFormV3Factory({
					name: 'leveraged-confirm-form',
					onSubmit: function (post_data) {
					},
					onComplete: function (obj) {
					},
					submit_button: false,
					steps: [
						{
							columns: [{
								directive: 'input',
								width: 100,
								data: {
									directive: 'checkbox',
									data: {
										id: 'leveraged-confirm-form-input-check',
										label: "",
										stretch: true,
										required: true,
										options: [{
											label_i18n: "transfers.new.leveraged_confirm.checkbox",
											model: 'approve',
											value: false,
											required: true
										}],
										size: '',
										post_var: 'confirmed_not_leveraged_position',
										onChange: function (val) {
											var isButtonActive = false;

											var isInvalid = function (data) {
												return data == undefined || data == ''
											}
											if ($scope.data.is_eft && $scope.data.is_registered) {
												var registeredInput = NwuiInputService.inputs['registered-confirm-form-input-check'].model;

												if (isInvalid(val) || isInvalid(registeredInput))
													return false;

												isButtonActive = val[0].value === true && registeredInput[0].value === true;

											} else {
												if (isInvalid(val))
													return false;

												isButtonActive = val[0].value === true;
											}
											if (isButtonActive) {
												SubmitButton.disabled = false;
											} else {
												NwButtonService.buttons[$scope.card.buttons.submit.id].disabled = true;
											}
										}
									}
								}
							}]
						}
					]
				})
			}

			$scope.addPartialTransferRowButton = new NwuiButtonV3Factory({
				id: 'add-partial-transfer-row-button',
				icon: {
					name: 'add'
				},
				onClick: function () {
					addPartialTransferAsset();
				}
			});


			function removePartialTransferRow(partialRowIndex) {
				$scope.partialTransferAssetData.splice(partialRowIndex, 1);

				updatePartialTransfersTable();
			}

			function addPartialTransferAsset() {
				var isPartialTransferValid = NwuiFormV3Service.validate($scope.partialAssetForm.name);
				if (isPartialTransferValid === true) {
					$scope.partialTransferAssetData.push(NwuiFormV3Service.getFormData($scope.partialAssetForm.name));
					updatePartialTransfersTable();
					NwuiFormV3Service.clear($scope.partialAssetForm.name);
					$scope.partialAssetForm = createCashOrGicForm();
				}
			}

			function transferTypeOptions(multipleEnabled) {

				// hide the two options not used by vpic for phase 1.
				// delete this if condition for vpic phase 2. 
				if (organization_data.slug === "vpic") {
					return [{
						label_i18n: 'nwui.common.input.is_partial_transfer.radio_0',
						model: "",
						value: 'full',
						required: false
					}, {
						label_i18n: 'nwui.common.input.is_partial_transfer.radio_2',
						model: '',
						value: 'fullinkind',
						required: false,
					}]
				}

				var options = [{
					label_i18n: 'nwui.common.input.is_partial_transfer.radio_0',
					model: "",
					value: 'full',
					required: false
				}, {
					label_i18n: 'nwui.common.input.is_partial_transfer.radio_1',
					model: '',
					value: 'partial',
					required: false
				}]

				// Enabled 2 more transfer type options for VPIC credential
				// Authorization to Transfer Account form 
				if (multipleEnabled) {
					options.push(
						{
							label_i18n: 'nwui.common.input.is_partial_transfer.radio_2',
							model: '',
							value: 'fullinkind',
							required: false,
						},
						{
							label_i18n: 'nwui.common.input.is_partial_transfer.radio_3',
							model: '',
							value: 'fullmixed',
							required: false,
						}
					)
				}

				return options;
			}

			function togglePartialBrokerageTransfer(isEnabled) {
				$scope.partialAssetForm = createCashOrGicForm();
				$scope.isPartialBrokerageTransfer = isEnabled;
			}

			function populatePartialTransfersTable() {
				return $scope.partialTransferAssetData.map(function (assetRow, index) {
					var description = '-';
					if (assetRow.description) {
						var date = moment(assetRow.description, 'YYYYMMDD')
						if (date.isValid()) {
							description = date.format(DATE_FORMAT.short);
						} else {
							description = assetRow.description;
						}
					}
					return {
						columns: [{
							text: locale.getString('nwui.common.form.select.option.asset_type.' + assetRow.type)
						}, {
							text: assetRow.amount != 0 && assetRow.amount != undefined ? $filter('NwuiCurrency')(assetRow.amount) : '-'
						}, {
							text: assetRow.share_count || '-'
						}, {
							text: assetRow.symbol || '-'
						}, {
							text: description
						}, {
							button: {
								processing: false,
								id: 'remove-partial-transfer-asset-' + index,
								icon_only: true,
								icon: 'close',
								onClick: function () {
									removePartialTransferRow(index);
								}
							}
						}]
					}
				});
			}

			function updatePartialTransfersTable() {
				$scope.partialTransferTable.rows = populatePartialTransfersTable();
			}

			function filterAssetTypes() {
				return NWUI_SELECT_OPTIONS.ASSET_TYPES.filter(function (asset) {
					return $scope.partialAssetTypes.indexOf(asset.value) > -1;
				});
			}

			var formCounter = 0;

			function createCashOrGicForm(currentSelectedType) {
				if ($scope.partialAssetForm)
					NwuiFormV3Service.clear($scope.partialAssetForm.name);
				var formToReturn;
				formCounter++;
				var formName = 'transfers-new-create-brokerage-transfer-partial-transfer-form-cash-' + formCounter;
				var columns = [
					{
						directive: 'input',
						width: 50,
						data: {
							directive: 'select',
							data: {
								label_i18n: 'nwui.common.input.bt_asset_type.label',
								model: currentSelectedType,
								id: 'create-brokerage-transfer-form-input-partial-type',
								stretch: true,
								required: true,
								post_var: 'type',
								options: filterAssetTypes(),
								placeholder_i18n: 'nwui.common.input.bt_asset_type.placeholder',
								onChange: function (val) {
									if (val === 'asset') {
										$scope.partialAssetForm = createAssetForm(val);
									} else if (val != undefined) {
										$scope.partialAssetForm = createCashOrGicForm(val);
									}
								}
							}
						}
					},
					{
						directive: 'input',
						width: 50,
						data: {
							directive: 'text',
							data: {
								label_i18n: 'nwui.common.input.bt_asset_amount.label',
								model: '',
								mask: 'currency',
								id: 'create-brokerage-transfer-form-input-partial-amount',
								hide: false,
								stretch: true,
								required: true,
								size: '',
								post_var: 'amount',
								placeholder_i18n: 'nwui.common.input.bt_asset_amount.placeholder',
								onChange: function (val) {
								}
							}
						}
					}
				];
				if (currentSelectedType === 'gic') {
					columns.push({
						directive: 'input',
						width: 50,
						data: {
							directive: 'text',
							data: {
								label_i18n: 'nwui.common.input.bt_asset_description.label',
								model: '',
								mask: 'date_dashed',
								validators: ['date_future'],
								id: 'create-brokerage-transfer-form-input-description',
								hide: false,
								stretch: true,
								required: true,
								size: '',
								post_var: 'description',
								placeholder_i18n: 'nwui.common.input.bt_asset_description.placeholder'
							}
						}
					})
				}
				formToReturn = new NwuiFormV3Factory({
					name: formName,
					submit_button: false,
					onSubmit: function (post_data) { },
					onComplete: function (obj) { },
					onError: function (error) { },
					steps: [{
						columns: columns
					}]
				});

				return formToReturn;
			}

			function createAssetForm(currentSelectedType) {
				if ($scope.partialAssetForm)
					NwuiFormV3Service.clear($scope.partialAssetForm.name);
				var formToReturn;
				formCounter++;
				var formName = 'transfers-new-create-brokerage-transfer-partial-transfer-form-asset-' + formCounter;
				formToReturn = new NwuiFormV3Factory({
					name: formName,
					submit_button: false,
					onSubmit: function (post_data) { },
					onComplete: function (obj) { },
					onError: function (error) { },
					steps: [{
						columns: [
							{
								directive: 'input',
								width: 50,
								data: {
									directive: 'select',
									data: {
										label_i18n: 'nwui.common.input.bt_asset_type.label',
										model: currentSelectedType,
										id: 'create-brokerage-transfer-form-input-partial-type',
										stretch: true,
										required: true,
										post_var: 'type',
										options: filterAssetTypes(),
										placeholder_i18n: 'nwui.common.input.bt_asset_type.placeholder',
										onChange: function (val) {
											if (val === 'asset') {
												$scope.partialAssetForm = createAssetForm(val);
											} else if (val != undefined) {
												$scope.partialAssetForm = createCashOrGicForm(val);
											}
										}
									}
								}
							},
							{
								directive: 'input',
								width: 50,
								data: {
									directive: 'text',
									data: {
										label_i18n: 'nwui.common.input.bt_asset_symbol.label',
										model: '',
										id: 'create-brokerage-transfer-form-input-partial-symbol',
										stretch: true,
										required: true,
										size: '',
										post_var: 'symbol',
										placeholder_i18n: 'nwui.common.input.bt_asset_symbol.placeholder',
										onChange: function (val) {
										}
									}
								}
							},
							{
								directive: 'input',
								width: 100,
								data: {
									directive: 'radio',
									data: {
										id: 'create-brokerage-transfer-form-choose-unit-or-amount',
										stretch: true,
										required: true,
										type: 'stacked',
										default_value: 'amount',
										options: [{
											label_i18n: 'nwui.common.input.bt_asset_amount.label',
											model: "",
											value: 'amount',
											required: false
										}, {
											label_i18n: 'nwui.common.input.bt_asset_share_count.label',
											model: '',
											value: 'unit',
											required: false
										}],
										size: '',
										post_var: '',
										onChange: function (inputVal) {
											if (inputVal === 'amount') {
												if (NwuiInputService.inputs['create-brokerage-transfer-form-input-partial-share-count']) {
													NwuiInputService.inputs['create-brokerage-transfer-form-input-partial-share-count'].model = '';
													NwuiInputService.inputs['create-brokerage-transfer-form-input-partial-share-count'].hide = true;
												}
												if (NwuiInputService.inputs['create-brokerage-transfer-form-input-partial-amount']) {
													NwuiInputService.inputs['create-brokerage-transfer-form-input-partial-amount'].hide = false;
												}
											} else if (inputVal === 'unit') {
												NwuiInputService.inputs['create-brokerage-transfer-form-input-partial-amount'].model = '';
												NwuiInputService.inputs['create-brokerage-transfer-form-input-partial-share-count'].hide = false;
												NwuiInputService.inputs['create-brokerage-transfer-form-input-partial-amount'].hide = true;
											}
										}
									}
								}
							},
							{
								directive: 'input',
								width: 50,
								data: {
									directive: 'text',
									data: {
										label_i18n: 'nwui.common.input.bt_asset_amount.label',
										model: '',
										mask: 'currency',
										id: 'create-brokerage-transfer-form-input-partial-amount',
										hide: false,
										stretch: true,
										required: true,
										size: '',
										post_var: 'amount',
										placeholder_i18n: 'nwui.common.input.bt_asset_amount.placeholder',
										onChange: function (val) {
										}
									}
								}
							},
							{
								directive: 'input',
								width: 50,
								data: {
									directive: 'text',
									data: {
										label_i18n: 'nwui.common.input.bt_asset_share_count.label',
										model: '',
										mask: 'four_decimals',
										hide: true,
										id: 'create-brokerage-transfer-form-input-partial-share-count',
										stretch: true,
										required: true,
										size: '',
										post_var: 'share_count',
										placeholder_i18n: 'nwui.common.input.bt_asset_share_count.placeholder',
										onChange: function (val) {
										},
										onBlur: function (val) {
											var userInput = val;
											if (val.charAt(val.length - 1) === '.') {
												userInput = val.slice(0, -1);
											}
											else if (val.indexOf('.') === -1) {
												if (val.length > 7) {
													userInput = val.slice(0, 7);
												}
											}
											NwuiInputService.inputs['create-brokerage-transfer-form-input-partial-share-count'].model = userInput;
										}
									}
								}
							}
						]
					}]
				});

				return formToReturn;
			}

			$scope.partialTransferTable = new NwuiCardTableFactory({
				name: 'partial-brokerage-transfers-table',
				empty_text: '',
				columns: [{
					name_i18n: 'transfers.new.table.asset_type',
					directive: 'text',
					sortable: false
				}, {
					name_i18n: 'transfers.new.table.amount',
					directive: 'text',
					sortable: false
				}, {
					name_i18n: 'transfers.new.table.share_count',
					directive: 'text',
					sortable: false
				}, {
					name_i18n: 'transfers.new.table.symbol',
					directive: 'text',
					sortable: false
				}, {
					name_i18n: 'transfers.new.table.description',
					directive: 'text',
					sortable: false
				}, {
					name: '',
					directive: 'button',
					width: 70,
					sortable: false
				}],
				rows: populatePartialTransfersTable()
			})
		}
	])
